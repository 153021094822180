import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import { Link } from 'gatsby';

const UseStyles = makeStyles(styles);

export default function dzienJednosci2019(props) {
  const classes = UseStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          <h3>
            <Link to="/wspolnota/magnificat">Powrót do strony wspólnoty Magnificat</Link>
          </h3>
          <div style={{ color: 'black', padding: '5px ', margin: '0 auto' }} >
            <img src={'/communitiesSlides/magnificat/magnificat-pulikowski-2020.png'} alt={'konferencja szczęście w życiu a małżeństwo i rodzina 24-02-2020'} style={{ padding: '30px', margin: '5vw auto', width: '100%' }} />
          </div>
        </div>
      </div>
    </Layout>
  );
}
